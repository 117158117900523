import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/components/docs-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "profiles-and-persons",
      "style": {
        "position": "relative"
      }
    }}>{`Profiles and Persons`}<a parentName="h2" {...{
        "href": "#profiles-and-persons",
        "aria-label": "profiles and persons permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`Firstparty does not try and maintain a 1:1 relationship between a Profile and a person. Instead, Firstparty thinks of a
Profile as a representation of a person in a specific context.`}</p>
    <p>{`Suppose David Rose runs a small business and purchases items from your website using his phone or work computer for his
retail small business. Later, he purchases something for his home from your website using a different device, such as a
personal laptop or mobile phone.`}</p>
    <p>{`If your website uses Firstparty to collect customer Events,
Firstparty may create two different Profiles for David - one representing David as a business-owner, and another
representing David as an individual customer. This allows you to market differently to David as a business and David as
a consumer, meeting the needs of each role.`}</p>
    <h2 {...{
      "id": "properties",
      "style": {
        "position": "relative"
      }
    }}>{`Properties`}<a parentName="h2" {...{
        "href": "#properties",
        "aria-label": "properties permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`Like Events, Profiles may have a set of Properties that define that Profile. Unlike Events, Properties on a profile are
mutable - you may update them with any value at any time.`}</p>
    <blockquote>
      <p parentName="blockquote">{`A Profile's Properties are not version controlled - in other words, Firstparty does not maintain a historical record of
Property values on a Profile.`}</p>
    </blockquote>
    <p>{`Profile Properties should be used to describe a Profile with information that changes infrequently, or information that
is appended or incremented in a single direction. Properties such as a name or email address that are rarely changed,
or Properties like lifetime value are great for Profiles. Lists, such as all of a customer's purchases, or activities
the Profile has performed should be represented by Events, not Properties on the Profile.`}</p>
    <h2 {...{
      "id": "merging--reconciliation",
      "style": {
        "position": "relative"
      }
    }}>{`Merging & Reconciliation`}<a parentName="h2" {...{
        "href": "#merging--reconciliation",
        "aria-label": "merging  reconciliation permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`Firstparty will automatically try and merge two Profiles who share an
`}<a parentName="p" {...{
        "href": "/docs/properties/overview/#identifying-properties"
      }}>{`Identifying Property`}</a>{`. When Profiles are merged, the
Property values stored on the Profiles will be combined based on the settings of each individual Property.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      